
  import { Component, Vue } from 'vue-property-decorator'
  import login from '@/components/Login.vue'
  import {
    HEIGHT_CALC,
    resizeHeight,
    resizeWidth,
    TEXT_SIZE_H2,
  } from '@/utils/general'
  import { mapGetters } from 'vuex'

@Component({
  components: { login },
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class Auth extends Vue {
  isMobile!: boolean;
  theme: string = this.$vuetify.theme.dark ? 'dark' : 'light';
  calc = HEIGHT_CALC;
  textSizeH2 = TEXT_SIZE_H2;
  email = '';
  cycle = true;
  slides = [
    {
      title: require('@/assets/genio/genioIcon.svg'),
      subtitles: [
        '¡Bienvenido, Inicia sesión!',
        'Registra',
        'Tasa',
        'Inspecciona',
      ],
      items: [],
      image: null,
      background: 'secondary',
    },
    {
      title: require('@/assets/genio/genioIcon.svg'),
      subtitles: ['No es magia,', 'es GENIO'],
      items: [],
      image: require('@/assets/genio/home.svg'),
      background: 'primary',
    },
  ];

  get currentTheme (): string {
    // @ts-ignore
    return this.$vuetify.theme.themes[this.theme].background
  }

  get heightSize (): string {
    return resizeHeight(this.$vuetify)
  }

  get widthSize (): string {
    return resizeWidth(this.$vuetify)
  }
  }
